body {
  ont-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}


.app-logo,
.brand-icon-white {
  height: 5.5rem;
}

header {}

main h1 {
  font-size: 2.5rem;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  color: #222222;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #5cb874;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  color: #5cb874;
  padding: 8px 25px;
  margin-left: 30px;
  border-radius: 4px;
  border: 2px solid #5cb874;
  transition: 0.3s;
  font-size: 14px;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  background: #5cb874;
  color: #fff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #5cb874;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #222222;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #5cb874;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #5cb874;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}


#home {
  width: 100%;
  height: 70vh;
  overflow: hidden;
  position: relative;
}

#home .hero-container {
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition-property: opacity;
  background-position: center;
  background-image: url('./assets/3439041.jpg');
  background-size: cover;
}

#home .hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color:rgb(58 87 38 / 64%);

}

.hero-content {
  color: #fff;
  z-index: 1;
}

.mobile-nav-toggle {
  background-image: url('./assets/menu.svg');
  width: 24px;
  height: 24px;
}

.close-nav-toggle {
  background-image: url('./assets/x-white.svg');
  width: 24px;
  height: 24px;
  position: fixed;
  top: 15px;
  right: 15px;
}

.info-title {
  width: 24px;
  height: 24px;
}

.user-icon {
  background-image: url('./assets/user-search.svg');
}

.users-round {
  background-image: url('./assets/users-round.svg');
}

.app-window {
  background-image: url('./assets/app-window.svg');
}

.circle-dollar-sign {
  background-image: url('./assets/circle-dollar-sign.svg');
}

.clock-1 {
  background-image: url('./assets/clock-1.svg');
}

.book-check {
  background-image: url('./assets/book-check.svg');
}

.person-standing {
  background-image: url('./assets/person-standing.svg');
}

.plane-landing {
  background-image: url('./assets/plane-landing.svg');
}

.info-title-sub {
  font-weight: 500;
}

.info-title-sub:hover {
  color: #3ba936;
}

.spaced-content {
  gap: 5rem;
}

.why-us-contents {
  flex: 1 1 250px;
  max-width: 250px;
  text-align: center;
}

.why-us-container {
  flex-wrap: wrap;
  overflow: hidden;
}

#services {
  background-color: #f8fcf9;
}

#choose-destination {
  background: #5cb874;
  color: #fff;
}

.flag-container {
  flex-wrap: wrap;
  overflow: hidden;
}

.flag {
  flex: 1 1 100px;
  width: 96px;
  height: 96px;
  background-repeat: no-repeat;
}

.flag-poland {
  background-image: url('./assets/poland.png');
}

.flag-uk {
  background-image: url('./assets/united-kingdom.png');
}

.flag-france {
  background-image: url('./assets/france.png');
}

.flag-lithuania {
  background-image: url('./assets/lithuania.png');
}

.flag-germany {
  background-image: url('./assets/germany.png');
}

.flag-slovakia {
  background-image: url('./assets/slovakia.png');
}

#metrics {
  background-color: #FBFBFB;
}

.s-64 {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
}

.icon-students {
  background-image: url('./assets/students.png');
}

.icon-application {
  background-image: url('./assets/application.png');
}

.icon-university {
  background-image: url('./assets/university.png');
}

.icon-globe {
  background-image: url('./assets/globe.png');
}

.icon-employee {
  background-image: url('./assets/employees.png');
}

.custom-card {
  border-color: #fff;
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
  height: 300px;
  width: 345px;
  padding: 1.25rem;
  gap: 1rem;
  border-radius: 2px;
}

.custom-card:hover {
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.1);
}

.client-name {
  color: #3ba936;
}

.social-links i {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
}

.bxl-facebook {
  background-image: url('./assets/fb.png');
}

.bxl-instagram {
  background-image: url('./assets/insta.png');
}

.bxl-linkedin {
  background-image: url('./assets/linkedin.png');
}

footer {
  background-color: #090909;
  color: #fff;
}

footer .brand-name {
  font-weight: 800;
}

footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #449d5b;
}

.icon-phone {
  width: 24px;
  height: 24px;
  background-image: url('./assets/phone.png');
  background-size: contain;
}

.icon-mail {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-image: url('./assets/mail.png');
}

.icon-support {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-image: url('./assets/support-svgrepo-com.svg');
}

.mail-link {
  color: #fff;
  text-decoration: none !important;
}

.contact-us {
  width: 190px;
}

.about-us {
  position: relative;
  color: #444444;
}

.about-us::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.about-us::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #5cb874;
  bottom: 0;
  left: calc(50% - 20px);
}

.about-us-info {
  color: #919191;
}

.what-we-do-li, ul.about-us-info {
  list-style: none;
  margin: 0;
  padding: 0;
}

.what-we-do-li li, ul.about-us-info li {
  list-style: none;
  margin: 0;
  margin-bottom: .75rem;
  padding: 0;
  position: relative;
  z-index: 1;
  padding-left: 1.75rem;  
  font-size: 16px;
}

.what-we-do-li li::after, ul.about-us-info li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  z-index: 2;
  width: 17px;
  height: 17px;
  background-image: url('./assets/tick-green.svg')
}