/* OffCanvas.css */
.off-canvas {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-out;
}

.off-canvas-container {
  position: relative;
}


.off-canvas.open {
  left: 0;
}

.toggle-button {
  background-image: url('../assets//x.svg');
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
}